// JavaScript Document


jQuery( document ).ready(function($) {
    
	$(document).foundation();
	
	
	
	$('.hamburger').on('click', function(){
		
		$('.hamburger').toggleClass('is-active');
		$('.overlay-menu').toggleClass('is-active'); 
		$('html').toggleClass('locked');

	});
		$(window).scroll(function() {
		 if ($(this).scrollTop() > 250)
		 {
		  $('.sticky-header').addClass("active");
		 }
		 else
		 {
		  $('.sticky-header').removeClass("active");
		 }
		});
	
	
	//ACCORDION MOBILE MENU
	$('.is-accordion-submenu-parent a').click(function(){
        $(this).unbind('click');
	});

	//SCROLLREVEAL
	window.sr = ScrollReveal();

	sr.reveal('.rvl', { 
		duration: 500,
		distance:'0px',
		scale:1,
		delay:500
		});


	sr.reveal('.str', { 
		duration: 500,
		origin: 'bottom',
		scale: 1
	}, 100);

});
